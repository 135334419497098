<template>
  <div
    class=""
    style="background-color: #f5f6fa; height: 100vh; overflow: hidden"
  >
    <div class="list">
      <!-- <h3>入住信息</h3> -->
      <van-field
        v-model="datafrom.Name"
        required
        label="住客名称"
        placeholder="请输入住客名称"
      />
      <van-field
        v-model="datafrom.IdCard"
        label="身份证号"
        required
        placeholder="请输入身份证号"
        @blur="IdNum(datafrom.IdCard)"
      />
      <van-field
        v-model="datafrom.Mobile"
        label="联系手机"
        required
        placeholder="请输入联系手机"
      />
      <van-field
        v-model="datafrom.RoomNum"
        label="房间数量"
        type="digit"
        required
        placeholder="请输入房间数量"
      />
      <van-field
        v-model="datafrom.ArriveTime"
        name="ArriveTime"
        required
        label="预计到店时间"
        @click="arriveTimePopup"
        readonly="readonly"
        placeholder="请选择预计到店时间"
      />
      <van-popup
        v-model="arriveTime"
        position="bottom"
        round
        get-container="body"
        :style="{ height: '59%' }"
      >
        <div class="arriveTimeTitle">预计到店时间</div>
        <div>
          <div
            v-for="(item, index) in arriveTimeList"
            :key="index + 'aa'"
            class="arriveTimeBox"
            :class="datafrom.ArriveTime == item.time ? 'active' : ''"
            @click="arriveTimeClick(item)"
          >
            <div>{{ item.time }}</div>
          </div>
        </div>
      </van-popup>
      <!-- <van-field
        v-model="datafrom.InDate"
        name="InDate"
        required
        label="入住日期"
        @click="inDatePopup = true"
        readonly="readonly"
        placeholder="请选择入住日期"
      />
      <van-calendar v-model="inDatePopup" color="#1989fa" @confirm="onInDate" />
      <van-field
        v-model="datafrom.LeaveDate"
        name="InDate"
        required
        label="退房日期"
        @click="leaveDatePopup = true"
        readonly="readonly"
        placeholder="请选择退房日期"
      />
      <van-calendar
        v-model="leaveDatePopup"
        color="#1989fa"
        :min-date="minDate"
        @confirm="onLeaveDate"
      /> -->
    </div>
    <div style="position: fixed; bottom: 15px; width: 100%">
      <div style="width: 100%; text-align: center">
        <van-button class="button" @click="postfrom()">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { WxPreHotel } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import Vue from "vue";
import { Toast, Dialog } from "vant";
Vue.use(Toast, Dialog);
export default {
  data() {
    return {
      datafrom: {
        TypeId: "",
        Name: "",
        IdCard: "",
        Mobile: "",
        InDate: "",
        LeaveDate: "",
        RoomNum: "",
        ArriveTime: "",
      },
      arriveTime: false,
      inDatePopup: false,
      leaveDatePopup: false,
      minDate: undefined,
      arriveTimeList: [],
    };
  },
  mounted() {},
  methods: {
    // 选中入住时间
    onInDate(date) {
      this.inDatePopup = false;
      this.datafrom.InDate = this.formatDateTime(date);
    },
    // 选中退房日期
    onLeaveDate(date) {
      this.leaveDatePopup = false;
      this.datafrom.LeaveDate = this.formatDateTime(date);
    },
    formatDateTime(val) {
      var timestamp = Date.parse(val);
      var date = new Date(timestamp);
      //获取年份
      var Y = date.getFullYear();
      //获取月份
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      //获取当日日期
      // console.log();
      var day = date.getDate();
      var D = day < 10 ? "0" + day : day;

      this.minDate = new Date(Y, M - 1, D);
      return Y + "-" + M + "-" + D;
    },
    // 提交
    postfrom: function () {
      console.log(this.$route.params.start);
      console.log(this.$route.params.end);
      if (this.datafrom.Name == "") {
        Toast.fail("请输入住客名称");
        return false;
      }
      if (this.datafrom.IdCard == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
      if (this.datafrom.Mobile == "") {
        Toast.fail("请输入手机号");
        return false;
      }

      if (this.datafrom.ArriveTime == "") {
        Toast.fail("请选择预约时间");
        return false;
      }
      if (this.datafrom.RoomNum == "") {
        Toast.fail("请输入房间数量");
        return false;
      }
      // if (this.datafrom.InDate == "") {
      //   Toast.fail("请选择入住日期");
      //   return false;
      // }
      // if (this.datafrom.LeaveDate == "") {
      //   Toast.fail("请选择退房日期");
      //   return false;
      // }
      this.datafrom.OpenID = getOpenId();
      this.datafrom.TypeId = this.$route.params.typeId;
      this.datafrom.InDate = this.$route.params.start;
      this.datafrom.LeaveDate = this.$route.params.end;
      this.datafrom.ArriveTime = this.datafrom.ArriveTime + ":00";
      WxPreHotel(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
            // this.$router.push({
            //   name: "register",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },

    // 获取身份证
    IdNum(value) {
      // console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          // this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    //获取当前日期函数
    getNowFormatDate() {
      var timestamp = Date.parse(new Date());
      var date = new Date(timestamp);
      //获取年份
      var Y = date.getFullYear();
      //获取月份
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      //获取当日日期
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // console.log(D);
      var hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      // console.log(hh);
      if (hh < 14) {
        var hhh = 14;
      } else {
        var hhh = hh + 1;
      }
      // console.log("当前时间：" + Y + '-' + M + '-' + D);
      return Y + "-" + M + "-" + D + " " + hhh;
    },
    //获取明天日期函数
    getTomorrowFormatDate() {
      var timestamp = Date.parse(new Date());
      var date = new Date(timestamp);
      //获取年份
      var Y = date.getFullYear();
      //获取月份
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      //获取当日日期
      // console.log();
      var day = date.getDate() + 1;
      var D = day < 10 ? "0" + day : day;
      // console.log("当前时间：" + Y + '-' + M + '-' + D);
      return Y + "-" + M + "-" + D;
    },
    // 打开预计到店时间弹窗
    arriveTimePopup() {
      // console.log(this.getNowFormatDate());
      this.arriveTime = true;
      this.getTimeList(
        this.getNowFormatDate() + ":00:00",
        this.getTomorrowFormatDate() + " 06:00:00",
        1000 * 60 * 60
      );
    },
    // 选中预约时间
    arriveTimeClick(val) {
      this.datafrom.ArriveTime = val.time;
      // this.getBetweenTime('14:00', '06:00')
      this.arriveTime = false;
    },
    // 计算开始时间到结束时间
    getTimeList(start, end, interval) {
      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();
      const diff = (endTime - startTime) / interval;
      this.arriveTimeList = [];
      for (let i = 0; i <= diff; i++) {
        const temp = new Date(startTime + i * interval);
        // console.log(temp);
        let startDate2 = this.formatDate(temp);
        // console.log(startDate2)
        this.arriveTimeList.push({ time: startDate2 });
      }
      return this.arriveTimeList;
    },
    // 时间格式化
    formatDate(d) {
      var date = new Date(d);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "次日0" + date.getHours() : date.getHours()) +
        ":";
      var mm =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // return YY + MM + DD + ' ' + hh + mm + ss;
      return hh + mm;
    },
  },
};
</script>
<style scoped>
.list {
  position: relative;
  width: 86%;
  padding: 10px 3%;
  margin: 11px 4%;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 14px;
  color: #333;
  padding: 10px 20px 5px;
  justify-content: space-between;
}

.list .flex text:nth-child(1) {
  width: 40%;
}

.list .flex text:nth-child(2) {
  width: 60%;
}

.arriveTimeTitle {
  font-weight: 550;
  text-align: left;
  margin: 15px 10px;
}
.arriveTimeBox {
  width: 24.6%;
  text-align: center;
  background: #f8f8f8;
  padding: 9px;
  border-radius: 5px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #f8f8f8;
  line-height: 25px;
  color: #1e1e1e;
  font-family: PingFang SC;
}
.active {
  border: 1px solid #fede00;
  background-color: #fede00;
  color: #1e1e1e;
  font-family: PingFang SC;
  border-radius: 6px;
}

/deep/ .van-cell__title {
  max-width: 100px !important;
  min-width: 100px !important;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
}

/deep/ .van-cell {
  padding: var(--cell-vertical-padding, 12px)
    var(--cell-horizontal-padding, 18px);
}

/deep/ .van-field__placeholder {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #a2a2a2;
}
.button {
  padding: 0 !important;
  margin-bottom: 20px;
  width: 83% !important;
  height: 43px;
  line-height: 43px;
  background-color: #fede00;
  border-radius: 23.5px;
  font-size: 15px;
  font-family: PingFang SC;
  color: #1e1e1e;
  border-color: #fede00;
}

/* .button1 {
  padding: 0 !important;
  margin-bottom: 10px;
  width: 83% !important;
  height: 94rpx;
  line-height: 94rpx;
  background-color: #fede005e !important;
  border-radius: 47rpx;
  font-size: 40rpx;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1E1E1E;
} */
</style>